<script setup lang="ts">
import SubmitButton from "./SubmitButton.vue";
import * as Yup from "yup";
import {
  ErrorMessage,
  Field,
  Form as VForm,
  useFieldValue,
} from "vee-validate";
import { useAuthStore } from "~/store/auth";
import config from "~/config";
import { useReCaptcha } from "vue-recaptcha-v3";
import { ref, defineEmits, onMounted } from "vue";
const { buyPass, verifyPassPayment } = useBooking();
const { portList, passList, getPortList, getPassList, loading } = useListing();
const { $toast } = useNuxtApp();
const bookNowLoader = ref(false);

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const props = defineProps({
  isOTPModalOpen: {
    type: Boolean,
    default: false,
  },
  customerId: {
    type: String,
    required: true,
  },
  mobile_number: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["close", "otp-verified"]);
const isOpenModal = ref(false);
const otp = ref("");
const close = () => {
  isOpenModal.value = false;
  emit("close");
};
watch(
  () => props.isOTPModalOpen,
  (newVal, oldVal) => {
    if (newVal != undefined) {
      isOpenModal.value = newVal;
      // if(newVal) {
      //     timer.value = 10;
      //     interval.value = setInterval(() => {
      //         if (timer.value == 0) {
      //             clearInterval(interval.value);
      //         } else {
      //             timer.value--;
      //         }
      //     }, 1000);
      // }
    }
  }
);

watch(
  () => otp.value,
  (newVal: any, oldVal: any) => {
    if (newVal.toString().length == 6) {
      let payload = {
        otp: newVal,
      };
      onSubmitOTP(payload);
    }
  }
);
const verifyOTP = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required.")
    // .matches(phoneRegExp, 'Mobile number is not valid.')
    .min(6, "OTP must be of 6 digits.")
    .max(6, "OTP must be of 6 digits."),
});

const authStore = useAuthStore();
const submitLoading = ref(false);
const resendOtp = async (values: any) => {
  try {
    // submitLoading.value = true;

    // Send login request
    await recaptchaLoaded();
    const token = await executeRecaptcha('login');
    // const loginPayload: any = {
    //     ...values,
    //     "g-recaptcha-response": token,
    // };
    let loginApiResponse = await authStore.login({
      mobile_number: props.mobile_number,
      "g-recaptcha-response": token,
    });
    if (loginApiResponse.status == "success") {
      $toast.success(loginApiResponse.message);
    } else {
      $toast.error(loginApiResponse.message);
    }

    // submitLoading.value = false;
  } catch (error) {
    $toast.error(config.catchErrorMessage);
    // submitLoading.value = false;
  }
};

const onSubmitOTP = async (values: any) => {
  try {
    submitLoading.value = true;
    values.customer_id = props.customerId;
    // Send otp verification request
    const response = await authStore.verifyOTP(values);
    if (response.status == "success") {
      authStore.setAuth(response.data);
      isOpenModal.value = false;
      emit("close");
      if (authStore.passDetails) {
        loadRazorPay(authStore.passDetails);
      }
      $toast.success(response.message);
      emit("otp-verified");
    } else {
      authStore.setAuth(authStore.userEmptyObj);
      // authStore.setError(response.message);
      $toast.error(response.message);
    }
    submitLoading.value = false;
    otp.value = ''
  } catch (error) {
    $toast.error(config.catchErrorMessage);
    submitLoading.value = false;
    otp.value = ''
  }
};

const isNumber = (e: any) => {
  if (e.target.value.length > 5) {
    e.preventDefault();
  }
  return true;
};
// buy pass payment
const loadRazorPay = async (pass: any) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    $toast.error(config.catchErrorMessage);
  }

  const payload = {
    pass_id: pass.id,
    from_date: authStore.passDetails.fromDate,
    to_date:  authStore.passDetails.toDate,
  };
  const response = await buyPass(payload);
  if (response.status === "success") {
    displayRazorPay(response.data.amount, response.data.id, pass);
  }

};
const loadScript = (src: any) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
const router = useRouter();

const displayRazorPay = (amount: any, order: any, plan: any) => {
  const options = {
    key: import.meta.env.VITE_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
    amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "My Boat Ride",
    description: plan.plan_name,
    image: "~/assets/images/full_logo_blue.png",
    order_id: order, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: async function (response: any) {
     try{
      const data = {
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        // razorpay_signature: response.razorpay_signature,
      };
      bookNowLoader.value = true;
      const res = await verifyPassPayment(data);
      if (res.status === "success") {
        $toast.success("Buy pass successfully.");
        // window.location.href = "/buy-pass";
        const id = res.data;
        router.push({ path: `pass/${id}` });
        //  window.location.href = `pass/${id}`
      }
      else {
        $toast.error(res.message);
      }
    }catch (error) {
      $toast.error("An error occurred while verifying the payment.");
    }finally{

      authStore.passDetails = null; // Reset pass details
      document.body.style.overflow = "auto";
      bookNowLoader.value = false;
    }
    },
    prefill: {
      name: authStore.user.name,
      email: authStore.user.email,
      contact: authStore.user.mobile_number,
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#396697",
    },
    // modal: {
      // ondismiss: async function () {
      //   await getPassList(values);
      //   document.body.style.overflow = "auto";
      // },
    // },
  };
  const _window = window as any;
  var paymentObject = new _window.Razorpay(options);
  paymentObject.open();
  paymentObject.on("payment.failed", async function (response: any) {
    // paymentObject.close();
    // message.error(response.error.description);
    // await planSetting();
    alert("payment failed");
    authStore.passDetails = null;
    return;
  });
  authStore.passDetails = null;
  // rzp1.on("payment.failed", function (response:any) {
  //     alert(response.error.code);
  //     alert(response.error.description);
  //     alert(response.error.source);
  //     alert(response.error.step);
  //     alert(response.error.reason);
  //     alert(response.error.metadata.order_id);
  //     alert(response.error.metadata.payment_id);
  // });
  // document.getElementById("rzp-button1").onclick = function (e) {
  //     rzp1.open();
  //     e.preventDefault();
  // };
};
</script>

<template>
    <Loader :isLoading="bookNowLoader" v-if="bookNowLoader" />
  <div v-if="isOpenModal" id="modal" class="modal opacity-75" :class="isOpenModal ? 'show-modal' : ''" style="
      display: block;
      position: fixed;
      opacity: 1;
      z-index: 11000;
      left: 50%;
      margin-left: -925px;
      top: 100px;
    ">
    <div class="modal-content fwt-login-screen-popup">
      <span class="close-button modal_close" @click="close">×</span>
      <div class="user_register">
        <div class="fwt-verify-popup md:flex items-center">
          <div class="fwt-login-popup-logo md:mb-0 mb-5">
            <img src="assets/images/footer-logo.png" alt="footer-logo" />
          </div>
          <div class="fwt-login-popup-text sm:mt-16 mt-6 sm:mb-16 mb-6">
            <!-- <p class="uppercase mb-2.5">now you’re in !</p> -->
            <h2 class="fwt-text-heading">please verify here.</h2>
            <span class="text-[#f69532]">OTP sent.</span>
            <VForm class="form w-100" id="kt_verify_otp_form" @submit="onSubmitOTP" :validationSchema="verifyOTP">
              <!-- <Field
                tabindex="1"
                type="number"
                name="otp"
                autocomplete="off"
                @keypress="isNumber($event)"
                v-model="otp"
              /> -->
              <div class="otp-container">
                <label for="otp">OTP</label>

                <Field tabindex="1" type="number" name="otp" autocomplete="off" @keypress="isNumber($event)"
                  v-model="otp" :class="submitLoading ? 'disabled' : ''" />
                <!-- Loader -->
                <div class="otp-input-loader" v-if="submitLoading"></div>
              </div>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="otp" />
                </div>
              </div>
              <div class="fwt-login-btn-text sm:flex items-center mt-1">
                <!-- <SubmitButton :loading="submitLoading" /> -->
                <Timer :resendOtp="resendOtp" />
                <!-- <span class="text-[#B8904F]" @click="resendOtp">Resend</span> -->
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp-container {
  position: relative;
  display: inline-block;
}

Field {
  padding-right: 35px;
  /* Give space for the loader */
}

.otp-input-loader[data-v-a375a209] {
  position: absolute;
  top: 53%;
  right: 22px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin-a375a209 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>