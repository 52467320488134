<template>
    <div>
        <p class="text-[#737373] !pt-0" v-if="isCountingDown">Resend OTP after  : {{ minutes }}:{{ formattedSeconds }}
        </p>
        <span class="text-[#f69532] font-bold ps-0 cursor-pointer" @click="resendOtp" v-if="!isCountingDown">Resend OTP</span>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';

const props = defineProps({
    resendOtp : {
        type: Function,
        required: true
    }
})
const totalSeconds = ref(120); // 10 minutes in seconds
let timer = null;
const isCountingDown = ref(false);

const minutes = computed(() => Math.floor(totalSeconds.value / 60));
const formattedSeconds = computed(() => {
    const seconds = totalSeconds.value % 60;
    return seconds < 10 ? '0' + seconds : seconds;
});

onMounted(() => {
    startCountdown()
})
const startCountdown = () => {
    if (timer) {
        clearInterval(timer);
    }
    totalSeconds.value = 120; // Reset to 10 minutes
    isCountingDown.value = true;

    timer = setInterval(() => {
        if (totalSeconds.value > 0) {
            totalSeconds.value -= 1;
        } else {
            isCountingDown.value = false;
            clearInterval(timer);
        }
    }, 1000);
};

onBeforeUnmount(() => {
    if (timer) {
        clearInterval(timer);
    }
});
</script>

<style scoped>
#app {
    text-align: center;
    margin-top: 50px;
}
</style>